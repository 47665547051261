<template>
  <vue-final-modal class="modal-change-active-bonus">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('change-active-bonus')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-change-active-bonus__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>

      <card-cash-bonus v-if="activePlayerBonus" :bonusInfo="activePlayerBonus" hideButton />

      <template v-if="props.bonusInfo">
        <div class="modal-change-active-bonus__separator">
          <atomic-icon id="arrows-separator" filled />
          <span>{{ getContent(props.currentLocaleData, props.defaultLocaleData, 'separator') }}</span>
        </div>

        <card-cash-bonus :bonusInfo="props.bonusInfo" hideButton newType />
      </template>

      <div class="modal-change-active-bonus__actions">
        <button-base type="secondary-3" size="md" @click="closeModal('change-active-bonus')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'cancelButton') }}
        </button-base>

        <button-base type="secondary-1" size="md" :isDisabled="bonusProcessing" @click="cancelBonus">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'confirmButton') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIChangeActiveBonusModal, IPlayerBonus } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIChangeActiveBonusModal>;
    defaultLocaleData: Maybe<CIChangeActiveBonusModal>;
    bonusInfo?: IPlayerBonus;
  }>();

  const { getContent } = useProjectMethods();
  const { closeModal } = useModalStore();
  const bonusStore = useBonusStore();
  const { activePlayerBonus } = bonusStore;
  const { processingBonuses } = storeToRefs(bonusStore);
  const { alertsData, defaultLocaleAlertsData } = useGlobalStore();
  const { showAlert } = useLayoutStore();

  const bonusProcessing = computed(() => processingBonuses.value.includes(props.bonusInfo?.id || '-1'));
  const { activatePlayerBonus } = useCoreBonusApi();
  const cancelBonus = async (): Promise<void> => {
    if (bonusProcessing.value || !props.bonusInfo?.id) return;
    processingBonuses.value.push(props.bonusInfo.id);

    try {
      await activatePlayerBonus(props.bonusInfo.id);
      processingBonuses.value = processingBonuses.value.filter(id => id !== props.bonusInfo?.id);
      await closeModal('change-active-bonus');
    } catch {
      processingBonuses.value = processingBonuses.value.filter(id => id !== props.bonusInfo?.id);
      showAlert(alertsData?.global?.somethingWrong || defaultLocaleAlertsData?.global?.somethingWrong);
    }
  };
</script>

<style src="~/assets/styles/components/modal/change-active-bonus.scss" lang="scss" />
